import React, { useState, useEffect } from "react";
import "./HelpCenter.css";

const HelpCenter = () => {
  const categories = [
    { name: "Terms and Policies", description: "Read our policies and terms that apply to your use of our services.", link: "https://help.mytestapp.me/policies.html" },
    { name: "Privacy and Safety", description: "Understand how we protect your privacy and ensure your safety.", link: "https://help.mytestapp.me/privacy-and-safety.html" },
    { name: "Tips and Solutions", description: "Find helpful tips and troubleshooting solutions.", link: "https://help.mytestapp.me/tips-and-solutions.html" },
    { name: "Other Information", description: "Find additional resources and information about MyTestApp.", link: "https://help.mytestapp.me/other-information.html" },
  ];

  const articles = [
    { title: "MyTestApp Terms of Use", category: "Terms and Policies", link: "https://help.mytestapp.me/policies/mytestapp-terms.html" },
    { title: "Privacy Policy", category: "Privacy and Safety", link: "https://help.mytestapp.me/policies/privacy.html" },
    { title: "TerminalBox Website Terms & Conditions", category: "Terms and Policies", link: "https://terminalbox.mytestapp.me/terms-of-use.html" },
    { title: "Augmented Reality Test safety information", category: "Privacy and Safety", link: "https://help.mytestapp.me/privacy-and-safety/ar-test-safety-information.html" },
    { title: "Fix common issues that may occur when attempting to subscribe to MyTestApp+ or send feedback", category: "Tips and Solutions", link: "https://help.mytestapp.me/tips-and-solutions/common-issues-subscribing-feedback.html" },
    { title: "Contact MyTestApp Support", category: "Other Information", link: "https://help.mytestapp.me/contact.html" },
  ];

  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Load chatbot widget
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://staticcdn.mytestapp.me/Containers/AISupportAgent/api.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script); // Cleanup on unmount
    };
  }, []);

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    setSearchResults(
      articles.filter((article) =>
        article.title.toLowerCase().includes(query)
      )
    );
  };

  return (
    <div>
      <header>
        <a href="https://help.mytestapp.me"><img src="https://staticcdn.mytestapp.me/Containers/images/mytestapp.png" alt="MyTestApp Logo" /></a>
        <div className="spacer"></div>
        <a href="https://help.mytestapp.me"><div className="logo">{isMobile ? "MyTestApp" : "MyTestApp Support"}</div></a>
        <div className="spacer"></div>
        <nav>
          <ul className="nav-links">
            {isMobile ? (
              <div className="mobile-logo-menu-button">
                         <button
                           className="mobile-menu-button"
                           onClick={() => setIsMenuOpen(!isMenuOpen)}
                           aria-label="Toggle menu"
                         >
                           {isMenuOpen ? "\u2716" : "\u2630"}
                         </button>
                         {isMenuOpen && (
                  <ul className="mobile-menu">
                                                          <li><a href="https://help.mytestapp.me/policies.html">Policies</a></li>
                                                          <li><a href="https://help.mytestapp.me/privacy-and-safety.html">Safety</a></li>
                                                          <li><a href="https://help.mytestapp.me/tips-and-solutions.html">Tips</a></li>
                                                          <li><a href="https://help.mytestapp.me/other-information.html">Other</a></li>
                                                          <li><a href="https://help.mytestapp.me/contact.html">Contact</a></li>
                                                          <li><a href="https://www.mytestapp.me/download.html">Download</a></li>
                  </ul>
                )}
              </div>
            ) : (
                 <>
                        <li><a href="https://help.mytestapp.me/policies.html">Policies</a></li>
                        <li><a href="https://help.mytestapp.me/privacy-and-safety.html">Safety</a></li>
                        <li><a href="https://help.mytestapp.me/tips-and-solutions.html">Tips</a></li>
                        <li><a href="https://help.mytestapp.me/other-information.html">Other</a></li>
                        <li><a href="https://help.mytestapp.me/contact.html">Contact</a></li>
                        <li><a href="https://www.mytestapp.me/download.html">Download</a></li>
                </>
                 )}
          </ul>
        </nav>
      </header>
      <main>
        <section id="search" className="hero">
          <div className="hero-content">
            <h1>How can we help you?</h1>
            <input
              type="text"
              className="search-bar"
              placeholder="Search for help topics..."
              value={searchQuery}
              onChange={handleSearch}
            />
            {searchQuery && (
              <div className="search-results">
                <h2>Search Results:</h2>
                <ul>
                  {searchResults.length > 0 ? (
                    searchResults.map((article, index) => (
                      <li key={index}>
                        <a href={article.link} target="_blank" rel="noopener noreferrer">
                          {article.title}
                        </a>
                      </li>
                    ))
                  ) : (
                    <li>No articles found</li>
                  )}
                </ul>
              </div>
            )}
          </div>
        </section>
        <section id="categories" className="features">
          <h2>Categories</h2>
          <div className="feature-grid">
            {categories.map((category, index) => (
              <div key={index} className="feature-item">
                <h3>{category.name}</h3>
                <p>{category.description}</p>
                <button
                  className="view-button"
                  onClick={() => window.location.href = category.link}
                >
                  View
                </button>
              </div>
            ))}
          </div>
        </section>
      </main>
      <footer>
        <div className="footer-content">
          <p>&copy; 2024 Impact Tiling Group Pty Ltd. All rights reserved.</p>
          <div className="vertical-spacer"></div>
          <ul className="footer-links">
            <li><a href="https://help.mytestapp.me/policies.html">Policies</a></li>
            <li><a href="https://help.mytestapp.me/privacy-and-safety.html">Safety</a></li>
            <li><a href="https://help.mytestapp.me/tips-and-solutions.html">Tips</a></li>
            <li><a href="https://help.mytestapp.me/other-information.html">Other</a></li>
            <li><a href="https://help.mytestapp.me/contact.html">Contact</a></li>
            <li><a href="https://www.mytestapp.me/download.html">Download</a></li>
          </ul>
        </div>
      </footer>
    </div>
   );
};

export default HelpCenter;
